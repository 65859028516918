<template>
  <a-card>
    <div style="font-size: 28px; color: #000000">联系我们</div>
    <div style="font-size: 28px; color: #000000"></div>
    <div style="font-size: 20px; color: #000000; margin-top: 30px">
      如果您需要与研铁堂取得联络，可以通过以下方式联系我们：
    </div>
    <div style="margin-top: 20px; line-height: 40px">
      <div style="font-size: 18px; color: #000">
        <a-space>
          <a-icon style="color: #1890ff" type="phone" />电话：13162099114
        </a-space>
      </div>
      <div style="font-size: 18px; color: #000">
        <a-space>
          <a-icon
            style="color: #1890ff"
            type="mail"
          />邮箱：chuangxin2@114cxy.com
        </a-space>
      </div>
      <div style="font-size: 18px; color: #000">
        <a-space>
          <a-icon style="color: #1890ff" type="home" />地址：上海市奉贤区茂园路659号1001室
        </a-space>
      </div>
      <div style="font-size: 18px; color: #000">
        <a-space>
           <a-icon style="color: #1890ff" type="aliwangwang" />请扫码关注公众号：研铁堂 科技创新学堂（微信绑定小程序后可接收提醒服务消息）
        </a-space>
      </div>
      <div style="font-size: 18px; color: #000">
        <img src="@/assets/QR.png" alt="">
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  name: "contact",
};
</script>

<style>
</style>